import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "answer", "uncollapser", "collapser" ]

  toggle() {
    // change + into x
    this.uncollapserTarget.classList.toggle('collapsed');
    this.collapserTarget.classList.toggle('collapsed');

    // toggle folded class on 'foldable'
    this.answerTarget.classList.toggle('collapsed');
  }
}
