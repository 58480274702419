import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["photo"]

  connect() {
    var count = 0;
    setInterval(function() {
      var id = count % 6;
      var currentPhoto = document.querySelector(`.carousel-${id + 1}`);
      var nextPhoto = document.querySelector(`.carousel-${(id + 1) % 6 + 1}`);
      currentPhoto.classList.toggle('carousel-hidden');
      nextPhoto.classList.toggle('carousel-hidden');
      ++count;
    }, 8000);
  }
}
