import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["expandable", "expander", "collapser"]

  toggle() {
    // change + into x
    this.collapserTarget.classList.toggle('hidden');
    this.expanderTarget.classList.toggle('hidden');

    // toggle collapsed class on 'expandable'
    this.expandableTarget.classList.toggle('expanded');
  }
}
